<script setup lang="ts"></script>

<template>
  <div>
    <!--    <base-img class="avatar" />-->
  </div>
</template>

<style scoped lang="scss">
.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: var(--color-secondary-shade-200);
}
</style>
