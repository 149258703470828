<script setup lang="ts">
import AppNavigation from '~/components/app/navigations/AppNavigation.vue';
import { useAppStore } from '~/stores/appStore';
import { useBreakpoints } from '@vueuse/core';
import { onBeforeUnmount } from 'vue';

const { updateState, state } = useAppStore();

const breakpoints = useBreakpoints({
  'tab-port-small': 600,
  phone: 400,
});

const closeMenu = (e: Event) => {
  if (!e.target?.closest('aside')) {
    updateState('collapsedAppMenu', false);
  }
};

watch(
  () => breakpoints.phone,
  ({ value }) => {
    if (value) {
      window.addEventListener('click', closeMenu);
    }
  },
  { immediate: true },
);

onBeforeUnmount(() => {
  window.removeEventListener('click', closeMenu);
});
</script>

<template>
  <aside :class="{ collapsed: state.collapsedAppMenu }">
    <app-navigation :icon-only="state.collapsedAppMenu" />
    <base-icon
      @click="updateState('collapsedAppMenu', !state.collapsedAppMenu)"
      tag="button"
      class="btn-chevron"
      :class="{ 'btn-chevron--flip': state.collapsedAppMenu }"
      color="none"
      icon="chevron-right"
      size="small"
    />
  </aside>
</template>

<style scoped lang="scss">
aside {
  position: relative;
  box-shadow: $shadow-primary-500-small;
  z-index: 10;

  @include respond('phone') {
    box-shadow: $shadow-primary-500-small !important;
  }

  @include respond('tab-port-small') {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    box-shadow: none;
    z-index: 100;
    background-color: var(--color-black);
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
  }

  &.collapsed {
    @include respond('tab-port-small') {
      transform: translateX(0);
    }
  }

  .btn-chevron {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 0;
    bottom: 12%;
    padding: 0.6rem;
    transform: translateX(50%);
    color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    transition: box-shadow 0.2s ease-in-out;

    @include respond('tab-port-small') {
      position: fixed;
      transform: translateX(150%) scale(1.4);
    }

    &:hover {
      box-shadow: $shadow-primary-500-small;
    }

    &--flip {
      transform: rotateY(180deg) translateX(-50%);
    }
  }
}
</style>
