<script setup lang="ts">
import AppHeader from '@app/layouts/AppHeader.vue';
import SideBar from '@/components/app/bars/SideBar.vue';
import { useAppStore } from '@/stores/appStore';

const { state } = useAppStore();
</script>

<template>
  <div
    class="app-layout default-layout-styling"
    :class="{ 'app-layout--collapsed': state.collapsedAppMenu }"
  >
    <side-bar class="app-sidebar" />
    <app-header class="app-header" />
    <main class="app-main">
      <slot />
    </main>
  </div>
</template>

<style scoped lang="scss">
.app-main {
  overflow-y: auto;
}
.app-layout {
  background: var(--color-black);
  display: grid;
  grid-template-columns: 250px 1fr; /* Adjust 250px to the width you want for the sidebar */
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
  max-height: 100vh;
  transition: all 250ms ease-in-out;
  width: 100vw;
  overflow: hidden;

  @include respond('tab-port-small') {
    grid-template-columns: 1fr;
    grid-template-rows: min-content auto 1fr;
    grid-template-areas:
      'header'
      'sidebar'
      'main';
  }

  &--collapsed {
    grid-template-columns: min-content 1fr;
    @include respond('tab-port-small') {
      grid-template-columns: 1fr;
    }
  }

  .app-sidebar {
    grid-area: sidebar;
    padding: $spacing-small;
  }

  .app-header {
    grid-area: header;
    padding: $spacing-small;
  }

  .app-body {
    grid-area: main;
    overflow-y: auto;
  }
}
</style>
