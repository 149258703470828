<script setup lang="ts">
import useUserStore from '@/stores/userStore';
import LightDarkToggle from '@/components/library/action/LightDarkToggle.vue';
import { useI18n } from 'vue-i18n';

const { logout, state } = useUserStore();
const { displaySuccess } = useToast();
const { t } = useI18n();
const handleLogout = () => {
  logout();
  displaySuccess(t('auth.success.logout'));
};
</script>

<template>
  <header v-if="state.activeUser" class="header header--default">
    <base-language-selector />
    <div class="center-container">
      <base-avatar />
      <!--      <light-dark-toggle />-->
    </div>
    <base-button class="ml-auto btn-logout" size="small" @click="handleLogout"
      >Logout
    </base-button>
  </header>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  background-color: var(--color-purple-300);
}
.btn-logout {
  max-width: 10rem;
}

.center-container {
  display: flex;
  align-items: center;
  margin-right: 4rem;
  flex-grow: 1;

  > :last-child {
    margin-left: auto;
  }
}
</style>
