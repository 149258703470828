<script setup lang="ts">
import { v4 as createUid } from 'uuid';
import { version } from '~/package.json';

// ************* TYPES ************* //
interface Props {
  iconOnly?: boolean;
}

// ************* PROPS ************* //
const props = defineProps<Props>();

const route = useRoute();

const toolItems = computed(() => [
  {
    to: {
      name: 'app-index-dashboard',
    },
    translation: 'app.nav.dashboard',
    icon: route.name === 'app-index-dashboard' ? 'home-solid' : 'home-reg',
    id: createUid(),
  },
  {
    to: {
      name: 'app-index-tools-QrCodeGenerator',
    },
    translation: 'app.nav.qrGenerator',
    icon:
      route.name === 'app-index-tools-QrCodeGenerator'
        ? 'qr-code'
        : 'qr-code-mini',
    id: createUid(),
  },
  {
    to: {
      name: 'app-index-tools-GalaxyGenerator',
    },
    translation: 'app.nav.galaxyGenerator',
    icon:
      route.name === 'app-index-tools-GalaxyGenerator'
        ? 'globe-asia-australia-solid'
        : 'globe-asia-australia',
    id: createUid(),
  },
]);
</script>

<template>
  <nav class="app-nav">
    <div class="flex justify-between items-center mb-4">
      <nuxt-link to="/">
        <base-logo />
      </nuxt-link>
      <transition name="fade">
        <span v-if="!iconOnly" class="app-nav__version-number"
          >v {{ version }}
        </span>
      </transition>
    </div>
    <div class="mb-4">
      <transition name="fade">
        <h3 v-if="!iconOnly" class="nav-heading">Tools</h3>
      </transition>

      <ul class="links">
        <li v-for="link in toolItems" :key="link.id" class="links__item">
          <nuxt-link
            class="link"
            :class="{ 'link--center': iconOnly }"
            :to="link.to"
          >
            <base-icon :icon="link.icon" />
            <transition name="fade">
              <span v-show="!iconOnly" class="hidden">
                {{ $t(link.translation) }}
              </span>
            </transition>
          </nuxt-link>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <h3 v-if="!iconOnly" class="nav-heading hidden">Creators</h3>
    </transition>

    <transition name="fade">
      <h3 v-if="!iconOnly" class="nav-heading hidden">Messages</h3>
    </transition>
    <ol></ol>
  </nav>
</template>

<style scoped lang="scss">
// **
.app-nav {
  &__version-number {
    font-size: $font-body-2;
    color: var(--color-grey-700);
  }

  .nav-heading {
    font-size: $font-body-1;
    color: var(--color-grey-700);
    margin-bottom: 1.6rem;
  }

  .links {
    display: flex;
    flex-direction: column;

    &__item {
      .link {
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        gap: 1.2rem;
        align-items: center;
        font-size: $font-body-1;
        font-weight: 600;
        color: var(--color-grey-700);
        transition: color 0.2s ease-in-out;

        &--center {
          justify-content: center;
        }

        &:hover {
          background-color: var(--color-purple-300);
          color: var(--color-white);
        }

        // **
        // *  Link styling
        &.router-link-active {
          background-color: var(--color-purple-600);
          color: var(--color-white);
        }
      }
    }
  }

  .hidden {
    @include respond('phone') {
      display: none;
    }
  }
}
</style>
